import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../shared/auth.service';
import { exhaustMap, take } from 'rxjs/operators';
import { LangService } from '../shared/lang.service';

@Injectable()
export class ReqInterceptInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService,private lang:LangService) { }

  my_headers:HttpHeaders;


  
  intercept(req: HttpRequest<any>, next: HttpHandler): any {
    return this.authService.loginSession.pipe(
      take(1),
      exhaustMap(admin => {
        if (!admin) {
          return next.handle(req);
        }

        let jwtToken = '';
        this.authService.loginAdminData.jwt_token ? jwtToken = this.authService.loginAdminData.jwt_token : jwtToken = '';

        this.my_headers = new HttpHeaders()
          .append('admin_id', admin._id)
          .append('servertoken', admin.servertoken)
          .append('caller_type', (this.authService.loginAdminData.admin_type).toString())
          .append('jwt_token', jwtToken)
          .append('type_id', '1')

        // const modifiedRequest = req.clone({
        //   headers: this.my_headers,
        //   body:{...req.body,'admin_id':this.authService.loginAdminData._id,'server_token':this.authService.loginAdminData.servertoken}
        // });

        var modifiedRequest

        if (req.body instanceof FormData) {
          req.body.append('admin_id', admin._id)
          req.body.append('servertoken', admin.servertoken)
          req.body.append('jwt_token', jwtToken)
          modifiedRequest = req.clone({
            headers: this.my_headers
          });
        } else {

          var addBody = {}
          if (!req.body || !req.body.server_token) {
            addBody = {
              server_token: admin.servertoken
            }
          }
          modifiedRequest = req.clone({
            headers: this.my_headers,
            body: { ...req.body, admin_id: admin._id, ...addBody }
          });
        }

        return next.handle(modifiedRequest)
      })
    );

  }


}
